@tailwind base;
@tailwind components;
@tailwind utilities;

.linkhover {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.linkhover::before,
.linkhover::after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
}
.linkhover::before {
  background-color: #0a1fdf;
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}
.linkhover::after {
  content: attr(data-replace);
  height: 80%;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform .5s cubic-bezier(0.76, 0, 0.24, 1);
  color: #0a1fdf;
}

.linkhover:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}
.linkhover:hover::after {
  transform: translate3d(0, 0, 0);
}

.linkhover span {
  display: inline-block;
  transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

.linkhover:hover span {
  transform: translate3d(-200%, 0, 0);
}